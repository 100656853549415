import { IncomingHttpHeaders, IncomingMessage, ServerResponse } from 'http';
import { useMemo } from 'react';

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import { CountryPrefix } from '@crehana/ts-types';

import getCountryPrefixFromHeaders from '../utils/getCountryPrefixFromHeaders';
import parseCookies from '../utils/parseCookies';
import {
  appReleaseLink,
  cloudflareAccessLink,
  countryPrefixLink,
  crehanaCurrentUrlLink,
  distinctIdLink,
  errorLink,
  operationNameLink,
  userAgentLink,
} from './apollo-links';
import {
  GRAPH_ENDPOINT,
  GRAPH_ENDPOINT_AUTH,
  GRAPH_ENDPOINT_AUTHENTICATION,
  GRAPH_ENDPOINT_B2B,
  GRAPH_ENDPOINT_PAYMENTS,
  GRAPH_ENDPOINT_SEARCH,
  GRAPH_ENDPOINT_V3,
  GRAPH_ENDPOINT_V4,
  GRAPH_ENDPOINT_V4_B2B,
  GRAPH_ENDPOINT_V4_STAFF,
  GRAPH_ENDPOINT_V5,
} from './constants';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export type ResolverContext = {
  req?: IncomingMessage;
  res?: ServerResponse;
};

// @ts-ignore
// TODO: talk with the code owner to fix this code so we can enable this rule again

function createIsomorphLink(context: ResolverContext = {}) {
  const common = { credentials: 'include' };

  const mainLink = createHttpLink({
    uri: GRAPH_ENDPOINT,
    ...common,
  });

  const auth = split(
    operation => operation.getContext().clientName === 'auth',
    createHttpLink({
      uri: `${GRAPH_ENDPOINT_AUTH}`,
      ...common,
    }),
    mainLink,
  );

  const b2bLink = split(
    operation => operation.getContext().clientName === 'b2b',
    createHttpLink({
      uri: `${GRAPH_ENDPOINT_B2B}`,
      ...common,
    }),
    auth,
  );

  const v4Link = split(
    operation => operation.getContext().clientName === 'v4',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V4,
      ...common,
    }),
    b2bLink,
  );

  const v4b2bLink = split(
    operation => operation.getContext().clientName === 'v4.b2b',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V4_B2B,
      ...common,
    }),
    v4Link,
  );

  const v3Link = split(
    operation => operation.getContext().clientName === 'v3',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V3,
      ...common,
    }),
    v4b2bLink,
  );

  const v4staffLink = split(
    operation => operation.getContext().clientName === 'v4.staff',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V4_STAFF,
      ...common,
    }),
    v3Link,
  );

  const paymentsLink = split(
    operation => operation.getContext().clientName === 'payments',
    createHttpLink({
      uri: GRAPH_ENDPOINT_PAYMENTS,
      ...common,
    }),
    v4staffLink,
  );

  const v5Link = split(
    operation => operation.getContext().clientName === 'v5',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V5,
      ...common,
    }),
    paymentsLink,
  );

  const schemaLinks = split(
    operation => operation.getContext().clientName === 'search',
    createHttpLink({
      uri: GRAPH_ENDPOINT_SEARCH,
      ...common,
    }),
    v5Link,
  );

  const authenticationLink = split(
    operation => operation.getContext().clientName === 'authentication',
    createHttpLink({
      uri: GRAPH_ENDPOINT_AUTHENTICATION,
      ...common,
    }),
    schemaLinks,
  );

  return authenticationLink;
}

function createApolloClient(
  context?: ResolverContext,
  countryPrefix?: CountryPrefix | null,
  pageKey?: string,
) {
  let links = [
    errorLink,
    distinctIdLink,
    crehanaCurrentUrlLink,
    appReleaseLink,
    userAgentLink,
    cloudflareAccessLink,
    operationNameLink(pageKey),
    countryPrefixLink(countryPrefix),
    createIsomorphLink(context),
  ];

  if (
    typeof window === 'undefined' &&
    process.env.ENABLE_GRAPHQL_OPERATION_LOGS &&
    process.env.ENABLE_GRAPHQL_OPERATION_LOGS === 'true'
  ) {
    try {
      const logLink = new ApolloLink((operation, forward) => {
        const uniqueId = new Date().getTime();
        const requestId = `${uniqueId}-Request ${operation.operationName}`;

        console.log('\n=====================================================');
        console.log(requestId);
        console.time(requestId);
        console.log('=====================================================\n');
        return forward(operation).map(result => {
          const logStr = `Request Finished - OperationName: ${
            operation.operationName
          }, Status: ${(operation.getContext()?.response as Response).status}`;

          console.log(
            '\n=====================================================',
          );
          console.log(logStr);
          console.timeEnd(requestId);
          console.log(
            '=====================================================\n',
          );
          return result;
        });
      });

      links = [logLink, ...links];
    } catch (e) {
      console.error('error to log request info', e);
    }
  }

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from(links),
    credentials: 'include',
    cache: new InMemoryCache({
      typePolicies: {
        OrganizationNode: {
          merge: true,
          fields: {
            users: relayStylePagination(),
            notStudyingUsers: relayStylePagination(),
            teamsConnection: relayStylePagination(),
            classroomCourses: relayStylePagination(),
          },
        },
        UserOrganizationNode: {
          merge: true,
          fields: {
            users: relayStylePagination(),
          },
        },
        GoalNodeConnection: {
          merge: true,
          fields: {
            goals: relayStylePagination(['query']),
          },
        },
        WorkflowConnection: {
          merge: false,
          fields: {
            inductionWorkflows: relayStylePagination(['query']),
          },
        },
        Query: {
          fields: {
            courses: relayStylePagination(['query']),
            news: relayStylePagination(['query']),
            skillB2BResultTeamNew: relayStylePagination(['query']),
            featuredArticles: relayStylePagination(),
            coursesB2BAdmin: relayStylePagination(['query']),
            academies: relayStylePagination(['query']),
            packAcademies: relayStylePagination(['query']),
            inductionTasks: relayStylePagination(['query']),
            enrollments: relayStylePagination(['query']),
          },
        },
      },
    }),
  });
}

interface InitializeApolloOptions {
  initialState?: any;
  /**
   * Pages with Next.js data fetching methods, like `getStaticProps`, can send
   * a custom context which will be used by `SchemaLink` to server render pages
   */
  context?: ResolverContext;
  countryPrefix?: CountryPrefix | null;
  pageKey?: string;
  headers?: IncomingHttpHeaders;
}

export function initializeApollo(
  options: InitializeApolloOptions = {
    initialState: null,
    context: undefined,
    countryPrefix: '',
    headers: undefined,
  },
) {
  let { countryPrefix } = options;
  const { initialState, context, pageKey, headers } = options;

  if (!countryPrefix && headers) {
    countryPrefix = getCountryPrefixFromHeaders(headers);
  }

  // If we don't get the CountryPrefix from the server try to get it on the client
  if (!countryPrefix && typeof window !== 'undefined') {
    const country = parseCookies(document.cookie).__creh_country_code;

    if (country && country !== '*') {
      countryPrefix = country.toLowerCase() as CountryPrefix;
    }
  }

  const _apolloClient =
    apolloClient ?? createApolloClient(context, countryPrefix, pageKey);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(
  initialState: any,
  countryPrefix?: CountryPrefix,
  pageKey?: string,
) {
  const store = useMemo(
    () =>
      initializeApollo({
        initialState,
        countryPrefix,
        pageKey,
      }),
    [countryPrefix, initialState, pageKey],
  );

  return store;
}
