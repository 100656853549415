/**
 * In SSR we need to concat the crehana url plus the graphql endpoint so the apollo client will use the crehana api instead of the nextjs node server.
 * Ex, giving the endpoint "/api/v2/graph/" it will result in:
 *  Server => "https://www.crehana.com/api/v2/graph/"
 *  Client => "/api/v2/graph/"
 * It's not necessary in the client because the nextjs app will live inside the crehana domain
 */
const concatUrl = (endpoint: string) => {
  if (typeof window === 'undefined') {
    const apiUrl = process.env.API_URL || '';

    return `${apiUrl}${endpoint}`;
  }

  return endpoint;
};

const BASE_PATH = '/api/v2/graph';

export const GRAPH_ENDPOINT = concatUrl('/api/v2/graph/');

export const GRAPH_ENDPOINT_AUTH = concatUrl(`${BASE_PATH}/auth/`);

export const GRAPH_ENDPOINT_PAYMENTS = concatUrl(`${BASE_PATH}/payments/`);

export const GRAPH_ENDPOINT_PRIVATE = concatUrl(`${BASE_PATH}/private/`);

export const GRAPH_ENDPOINT_B2B = concatUrl(`${BASE_PATH}/b2b/`);

export const GRAPH_ENDPOINT_SEARCH = concatUrl(`${BASE_PATH}/search/`);

export const GRAPH_ENDPOINT_V3 = concatUrl('/api/v3/graph/');

export const GRAPH_ENDPOINT_V4 = concatUrl('/api/graph/');

export const GRAPH_ENDPOINT_V4_B2B = concatUrl('/api/graph/b2b/');

export const GRAPH_ENDPOINT_V4_STAFF = concatUrl('/api/graph/staff/');

export const GRAPH_ENDPOINT_AUTHENTICATION = concatUrl(
  '/api/graph/authentication/',
);

export const GRAPH_ENDPOINT_V5 = concatUrl('/api/v5/graph/');

export const CREDENTIALS = process.env.API_URL ? 'include' : 'same-origin';
