import { ApolloLink } from '@apollo/client';

import { getUserDistinctId } from '@crehana/utils';

const distinctIdLink = new ApolloLink((operation, forward) => {
  let distinctId = '';

  if (typeof window !== 'undefined') {
    distinctId = getUserDistinctId().distinctId;
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'session-distinct-id': distinctId,
    },
  }));

  return forward(operation);
});

export default distinctIdLink;
