import { UIThemeProvider as _UIThemeProvider } from "@crehana/layouts";

/** @deprecated Please import it from `@crehana/layouts` library. */
export var UIThemeProvider = _UIThemeProvider;
/** @deprecated Please import it from `@crehana/layouts` library. */
export default UIThemeProvider;
/** @deprecated Please import it from `@crehana/layouts` library. */
export { useUITheme } from "@crehana/layouts";

/** @deprecated Please import it from `@crehana/layouts` library. `import { UIThemeProvider } from '@crehana/layouts';` */
export { UIThemeProvider as UIThemeLayout };