import { useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';

import { install as installDevtools } from '@crehana/devtools';
import { I18nProvider, I18nProviderProps } from '@crehana/i18n';
import { addExtraOptionsToFechApi } from '@crehana/next';
import { CountryCode, LANGUAGES } from '@crehana/ts-types';
import { Layout as CrehanaUILayout } from '@crehana/ui';
import { AmplitudeLayout, UIThemeLayout } from '@crehana/web/Layouts';
import { AuthProvider } from '@crehana/web/ReactContexts/AuthContext';
import { CoursePreviewProvider } from '@crehana/web/ReactContexts/CoursePreviewContext';
import { SavedCoursesProvider } from '@crehana/web/ReactContexts/SavedCoursesContext';
import { TrackingProvider } from '@crehana/web/ReactContexts/TrackingContext';

import FacebookPixel from '@/shared/components/FacebookPixel/FacebookPixel';
import Channels from '@/shared/context/Channels';
import { useApollo } from '@/shared/libs/apollo';

import 'intersection-observer';
import '@crehana/tailwindcss/dist/crehana-tailwindcss.css';
import '@/shared/styles/main.scss';
// eslint-disable-next-line no-restricted-imports
import '@crehana/ui/dist/crehana-ui.min.css';
import 'nprogress/nprogress.css';

addExtraOptionsToFechApi();

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export interface MyAppProps extends AppProps<{ err: unknown }> {
  err: unknown;
  pageProps: {
    err: unknown;
    i18nProviderProps?: Partial<I18nProviderProps>;
    countryPrefix: CountryCode;
    initialApolloState: unknown;
    pageKey: string;
  };
}

const MyApp = ({ Component, pageProps, err }: MyAppProps) => {
  const apolloClient = useApollo(
    pageProps.initialApolloState,
    pageProps.countryPrefix,
    pageProps.pageKey,
  );

  useEffect(() => {
    installDevtools();
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=0"
        />
        <link
          rel="preload"
          href="https://static.crehana.com/static/fonts/google-fonts/nunito/regular/Nunito-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
      </Head>

      {/* force inline this class names */}
      <span
        className="cui-is-visible cui-is-hidden"
        style={{
          opacity: 0,
          visibility: 'hidden',
          height: 0,
          width: 0,
          position: 'absolute',
        }}
        aria-hidden
      />

      <ApolloProvider client={apolloClient}>
        <AuthProvider
          onLogout={() => {
            window.location.href = `/logout/?next=${window.location.pathname}`;
          }}
        >
          <CrehanaUILayout>
            <TrackingProvider>
              <AmplitudeLayout
                apiKey={process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!}
              >
                <UIThemeLayout>
                  <I18nProvider
                    languageCode={LANGUAGES.ES}
                    countryCode={pageProps.countryPrefix}
                    fallbackNs={['main-menu']}
                    fallbackLanguageCode={LANGUAGES.ES}
                    {...(pageProps.i18nProviderProps || {})}
                  >
                    <CoursePreviewProvider>
                      <SavedCoursesProvider>
                        <Component {...pageProps} err={err} />
                      </SavedCoursesProvider>
                    </CoursePreviewProvider>
                  </I18nProvider>
                </UIThemeLayout>
              </AmplitudeLayout>
            </TrackingProvider>

            <Channels countryPrefix={pageProps.countryPrefix} />
          </CrehanaUILayout>
        </AuthProvider>
      </ApolloProvider>

      <FacebookPixel />

      <Script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config','${process.env.NEXT_PUBLIC_GTAG_ID}');`,
        }}
        strategy="beforeInteractive"
        id="crehana-datalayer-script"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}')`,
        }}
        strategy="afterInteractive"
        id="crehana-google-tag-manager-script"
      />
      {/* <Script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=sB0_agkRKeNxCcW_yGSa1w&gtm_preview=env-137&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID_2}')`,
        }}
        strategy="afterInteractive"
      /> */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GANALYTICS_ID}`}
        strategy="afterInteractive"
      />
    </>
  );
};

export default MyApp;
