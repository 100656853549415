import { ApolloLink } from '@apollo/client';

const appReleaseLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      // eslint-disable-next-line turbo/no-undeclared-env-vars
      'creh-app-version': process.env.NEXT_PUBLIC_COMMIT_SHA,
    },
  }));

  return forward(operation);
});

export default appReleaseLink;
