import { IncomingHttpHeaders } from 'http';

import { CountryCode } from '@crehana/ts-types';

import parseCookies from './parseCookies';

function getCountryPrefixFromHeaders(
  headers: IncomingHttpHeaders,
): CountryCode | null {
  let countryCode: CountryCode | null = null;
  const countryFromHeader = headers['creh-country'] as CountryCode;

  if (countryFromHeader) {
    countryCode = (
      countryFromHeader === '*' ? '' : countryFromHeader.toLowerCase()
    ) as CountryCode;
  } else if (headers.cookie) {
    const country = parseCookies(headers.cookie).__creh_country_code;

    if (typeof country === 'string') {
      const countryPrefix = (
        country !== '*' ? country.toLowerCase() : ''
      ) as CountryCode;

      countryCode = countryPrefix;
    }
  }

  return countryCode;
}

export default getCountryPrefixFromHeaders;
