import { ApolloLink } from '@apollo/client';
import { OperationDefinitionNode } from 'graphql';

import { md5 } from '@crehana/utils';

const operationNameLink = (pageKey?: string) =>
  new ApolloLink((operation, forward) => {
    if (operation.operationName) {
      const { operationName, query, variables } = operation;
      const h: Record<string, string> = {
        'graphql-operation-type': 'query',
        'graphql-operation-name': operationName,
      };
      const graphqlOperationDefinition = query.definitions.find(
        d => d.kind === 'OperationDefinition',
      );

      if (graphqlOperationDefinition) {
        h['graphql-operation-type'] = (
          graphqlOperationDefinition as OperationDefinitionNode
        ).operation;
      }

      if (pageKey) {
        h['graphql-operation-source'] = pageKey;
      }

      try {
        h['graphql-operation-id'] = md5(
          unescape(
            decodeURIComponent(JSON.stringify({ operationName, variables })),
          ),
        );
      } catch (e) {
        console.error('Falied to stringify operationid', e);
      }
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          ...h,
        },
      }));
    }

    return forward(operation);
  });

export default operationNameLink;
