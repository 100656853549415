import { ApolloLink } from '@apollo/client';

import { CountryPrefix } from '@crehana/ts-types';

const currencyLink = (countryPrefix?: CountryPrefix | null) =>
  new ApolloLink((operation, forward) => {
    let hs = {};

    if (
      countryPrefix ||
      // @ts-expect-error the CountryPrefix type doesn't include '*' but is a posibility
      countryPrefix !== '*'
    ) {
      hs = {
        'creh-country': countryPrefix,
      };
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...hs,
      },
    }));

    return forward(operation);
  });

export default currencyLink;
