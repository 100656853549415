import { ApolloLink } from '@apollo/client';

const userAgentLink = new ApolloLink((operation, forward) => {
  if (typeof window === 'undefined') {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'user-agent': 'Crehana-Vercel',
      },
    }));
  }

  return forward(operation);
});

export default userAgentLink;
