function parseCookies(cookieString: string): Record<string, string> {
  if (cookieString) {
    const cookiesArray = cookieString.split(';');

    const cookiesObj = cookiesArray.reduce((acc, cookie: string) => {
      const parts = cookie.split('=');
      const part = parts.shift();

      if (part) {
        return {
          ...acc,
          [part.trim()]: decodeURI(parts.join('=')),
        };
      }
      return acc;
    }, {} as Record<string, string>);

    return cookiesObj;
  }
  return {};
}

export default parseCookies;
